@import "./root.scss";

tbody,
td,
tfoot,
th,
thead,
tr {
    padding: 4px;
}

body {
    margin: 0 auto;
    padding: 0;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 400 !important;
    font-size: var(--font13) !important;
    color: var(--text) !important;
    overflow-x: hidden !important;
    background-color: var(--lightbg) !important;
}

/*--------- header --------*/

header {
    width: 60px;
    background-color: var(--primary);
    padding: 17px 0px;
    text-align: center;
    height: 100vh;
    position: fixed;
    z-index: 999;
}

/*--------- wrapper --------*/

.mainwrap {
    padding: 16px 5px 18px 65px !important;
}

/*--------- dashboard --------*/

.dashcol {
    border-radius: 6px;
    height: 100%;
    padding: 17px 20px;
    position: relative;
    box-shadow: 5px 5px 10px #b8b8b8, -5px -5px 10px #ededed;
}

.dashcol label {
    font-weight: bold;
}

.dashcol a {
    height: 100%;
    color: var(--text);
    display: block;
}

.btnStyle.filters {
    margin-left: 5px !important;
    margin-right: 0px !important;
    height: 38px !important;
}

.Custom_tooltip .btnStyle.export-btn {
    margin-left: 0px !important;
    height:38px
}

.dashcol h4 {
    font-weight: var(--bold);
    text-transform: capitalize;
    margin-bottom: 12px !important;
    color: var(--primary);
}

.actright {
    display: flex;
}

.actnav {
    display: grid;
    grid-template-columns: 34% 57% 9%;
    margin-bottom: 18px;
    align-items: center;
}

.actnav .frmgroup {
    margin-bottom: 0;
    margin-right: 5px;
}

.flowact-nav .frmgroup {
    width: 25%;
    margin-bottom: 0;
    margin-right: 20px;
}

.dashfltr {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filtrbtn {
    margin-left: 17px;
}

.filtercol {
    margin-bottom: 10px;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    position: relative;
}

.actnav {
    margin-bottom: 10px;
}

a.viewlink {
    font-size: 12px;
    font-weight: var(--regular);
    color: var(--primary);
    text-decoration: underline !important;
    text-align: right;
    margin: 0 0 0 auto;
}

.fltrhead {
    display: flex;
}

.fltrhead .graph-des-nav {
    margin-left: 40px;
}

.offcanvas {
    transition: transform .6s ease-in-out;
}

.offcanvas-map-new {
    height: 100vh;
}

.offcanvasPadding {
    padding: 30px 25px 30px 25px;
}

.offcanvas-end {
    top: 0;
    right: 0;
    width: 90%;
}

.offcanvas-backdrop {
    background-color: rgba(0, 0, 0, 58%) !important;
}

.offcanvas-backdrop.show {
    opacity: 1 !important;
}

.offcanvas .btn-close {
    box-sizing: content-box;
    width: 35px;
    height: 40px;
    padding: 0px 10px;
    color: var(--primary);
    background: none;
    border: 0;
    border-radius: 30px 0 0 30px;
    opacity: 1;
    position: absolute;
    left: -50px;
    background-color: #fff;
    font-size: 32px;

    font-weight: 200;
    top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.offcanvas-header .btn-close {
    margin-top: 2.5rem;
}

.flowhead {
    .p-0 {
        font-size: 18px !important;
        font-weight: normal !important;
    }
}

.flowhead {
    font-size: var(--font20);
    font-weight: var(--bold);
    color: var(--primary);
    margin-bottom: 5px !important;
}

.flowbox-row {
    display: flex;
}

.flowbox {
    padding: 12px 12px;
    background-color: var(--lightbg);
    width: 100%;
    margin: 1%;
    border-radius: 6px;
    border: 1px solid transparent;
    transition: all 0.4s linear;
    cursor: pointer;
    flex: 1;
}

.flowbox:first-child {
    margin-left: 0;
}

.flowbox h4 {
    font-size: var(--font16);

    font-weight: var(--bold);
    color: var(--gray3);
    text-transform: capitalize;
}

.flowbox h2 {
    font-weight: var(--light);
    color: var(--gray3) !important;
    padding-top: 30px;
}

.dark-blueline {
    border: 1px solid var(--blue);
}

.blueline {
    border: 1px solid var(--blue-light);
}

.pinkline {
    border: 1px solid var(--pink);
}

.purpleline {
    border: 1px solid var(--purple);
}

.yellowline {
    border: 1px solid var(--yellow);
}

.orangeline {
    border: 1px solid var(--orange);
}

.flow-descriptions-block {
    border: 1px solid var(--border);
    border-radius: 6px;
    margin: 20px 5px 0;
}

.stories-list {
    padding: 19px;
}

.stories-list h5 {
    font-size: var(--font12);
    color: var(--dark);
    font-weight: var(--semibold);
    margin-bottom: 35px;
}

.stories-list ol {
    list-style: none;
    counter-reset: my-awesome-counter;
}

.stories-list ol li {
    position: relative;
    font-size: var(--font16);
    color: var(--text);
    font-weight: var(--regular);
    counter-increment: my-awesome-counter;
    border-bottom: 1px solid var(--border);
    display: flex;
    align-items: center;
}

.stories-list ol li::before {
    content: counter(my-awesome-counter);
    font-size: var(--font16);
    //  
    color: var(--text);
    font-weight: var(--regular);
    left: 1%;
    position: absolute;
}

.stories-list ol li:last-child {
    border-bottom: 0;
}

.numbox {
    width: 50%;
    padding: 5px;
    margin-bottom: 5px;
    color: var(--gray1);
}

.numdes {
    font-size: 35px;
    font-weight: var(--regular);
}

.flowblock {
    overflow-y: auto;
}

.flownum {
    font-size: 40px;
    font-weight: var(--regular);
}

.flownum span {
    font-size: 20px;
}

.dark-blue {
    color: var(--blue)
}

.blue {
    color: var(--blue-light)
}

.pink {
    color: var(--pink)
}

.purple {
    color: var(--purple)
}

.yellow {
    color: var(--yellow)
}

.orange {
    color: var(--orange)
}

/*---------------- flow load, distribution, velocity active ----------------*/

.flowacti-block .dark-blueline.active {
    background-color: var(--blue);
    border: 1px solid var(--blue);
}

.flowacti-block .blueline.active {
    background-color: var(--blue-light);
    border: 1px solid var(--blue-light);
}

.flowacti-block .pinkline.active {
    background-color: var(--pink);
    border: 1px solid var(--pink);
}

.flowacti-block .purpleline.active {
    background-color: var(--purple);
    border: 1px solid var(--purple);
}

.flowacti-block .yellowline.active {
    background-color: var(--yellow);
    border: 1px solid var(--yellow);
}

.flowacti-block .orangeline.active {
    background-color: var(--orange);
    border: 1px solid var(--orange);
}

.flowacti-block .active {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.10);
}

.flowacti-block .active h4,
.flowacti-block .active h2,
.flowacti-block .active h5 {
    color: var(--white);
}

.flowacti-block .blueline.active h4,
.flowacti-block .blueline h2,
.flowacti-block .blueline h5 {
    color: #005F6C;
}

.flow-descriptions-block .accordion-button:not(.collapsed) {
    color: #4E4E4E;
    background-color: transparent;
    box-shadow: inset 0 0 0 rgb(0 0 0 / 13%);
}

.flow-descriptions-block .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 0px;
    font-size: 16px;
}

.flow-descriptions-block .accordion-item {
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #D6D9DC;
}

.flow-descriptions-block .accordion-body {
    padding: 0rem 0px 10px;
}

.flow-descriptions-block .accordion-item:last-of-type {
    border-bottom: 0;
}

.flow-descriptions-block .accordion-button span {
    margin-right: 30px;
}

.flow-descriptions-block .accordion-button::after {
    display: none;
}

.stories-list .bullet-list ol li::before {
    content: "";
    left: 0;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #A6CE38;
    border-radius: 10px;
}

.stories-list .bullet-list ol li {
    padding: 15px 0px 15px 25px;
}

.flowpredi-des .stories-list h5 {
    margin-bottom: 20px;
}

.stories-list .bullet-list ol {
    margin-left: 33px;
}

.flowact-nav {
    margin-bottom: 5px;
    display: flex;
}

.managecol {
    background-color: var(--lightbg);
    border-radius: 5px;
    color: var(--black);
    width: 21%;
    margin: 1%;
    padding: 0 13px 13px;
    cursor: pointer;
    transition: all 0.4s linear;
}

.managecol:last-child {
    margin-right: 0;
}

.managecol h4 {
    font-size: var(--font16);
    font-weight: var(--regular);
    text-transform: capitalize;
    padding: 10px 0;
}

.managecol h2 {
    font-size: var(--font25);
    //  
    font-weight: var(--medium);
}

.dark-blue-border {
    border-top: 5px solid var(--blue);
}

.blue-border {
    border-top: 5px solid var(--blue-light);
}

.pink-border {
    border-top: 5px solid var(--pink);
}

.red-border {
    border-top: 5px solid #FB543A;
}

.major-border {
    border-top: 5px solid #fec82f;
}

.prHigh-border {
    border-top: 5px solid #fda26b;
}

.prLow-border {
    border-top: 5px solid #98bddf;
}


.purple-border {
    border-top: 5px solid var(--purple);
}

.managecol.active {
    color: var(--white);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
}

.dark-blue-border.active {
    background-color: var(--blue);
}

.blue-border.active {
    background-color: var(--blue-light);
}

.pink-border.active {
    background-color: var(--pink);
}

.red-border.active {
    background-color: #FB543A;
}

.major-border.active {
    background-color: #fec82f;
}

.prHigh-border.active {
    background-color: #fda26b;
}

.prLow-border.active {
    background-color: #98bddf;
}


.purple-border.active {
    background-color: var(--purple);
}

.circle {
    border-radius: 100%;
    background-color: var(--primary);
    color: var(--white);

    font-weight: var(--regular);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.circle span {
    font-weight: var(--bold);
}

.mtr {
    width: 50%;
}

.intype .managecol {
    width: 40%;
    margin: 0;
}

.accordion-button::after {
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    margin-left: auto;
    content: "";
    // background-image: url(../images/arrow.svg);
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    transition: transform .2s ease-in-out;
    border: 1px solid var(--gray1);
    border-radius: 40px;
}

.accordion-button:not(.collapsed)::after {
    transform: rotate(-180deg);
}

.accordion-body .intype {
    width: 48%;
}

.filternav,
.filterwrap {
    display: flex;
    align-items: center;
}

.filternav {
    padding: 18px 0 5px;
}

.filterwrap {
    width: 90%;
    padding: 0px 5px 5px 5px;

}

.pagecount {
    width: 14%;
    display: flex;
    justify-content: flex-end;
}

.filterwrap .frmgroup {
    margin-bottom: 0;
    width: 200px;
    margin-right: 10px;
}

.filterwrap .frmgroup .form-check-input[type="radio"] {
    width: 20px;
    height: 20px;
    border: 1px solid var(--secondary-light);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 0;
    background-color: var(--white);
}

.filterwrap .frmgroup .form-check-input:checked[type="radio"] {
    background-color: var(--white);
    background-size: 8px 8px;
    border-color: var(--secondary-light);
}

.pagecount .frmgroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.frmgroup.radiogroup {
    width: 15%;
}

.filterwrap .frmgroup.radiogroup label {
    display: block;
}

.filterwrap .radiogroup .form-check-inline {
    display: inline-flex;
    margin-right: 1rem;
    align-items: center;
}

.filterwrap .radiogroup .form-check {
    min-height: 37px;
}

.pagecount .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 56px;
}

.filterwrap .radiogroup .form-check-label {
    margin-bottom: 0;
}

.filterwrap .filtrbtn {
    margin-top: 12px;
}

.grid-table table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 1px;
    margin-bottom: 0px;
}

.grid-table table .thead {
    background-color: var(--white);
    //border-radius: 8px 8px 0 0;
}

.grid-table table .thead th {
    font-size: 13px;

    font-weight: var(--bold);
    color: var(--text);
    padding: 30px 20px 17px;
}

.grid-table table tr {
    border-bottom: 1px solid #d5d5d5;
}

.grid-table table tr:last-child {
    border-bottom: 1px solid #EAEAEA;
}

.grid-table table tr:first-child {
    border-bottom: 1px solid #d5d5d5;
}

.grid-table .action tr {
    border-bottom: 0 !important;
}

.grid-table table tr td {
    font-size: 13px;
    color: var(--text);
    font-weight: var(--regular);
    padding: 10px 20px;
    white-space: initial;
}

@media (min-height: 798px) and (max-height: 958px) {
    .grid-table table tr td {
        font-size: 13px;
        color: var(--text);
        font-weight: var(--regular);
        padding: 20px 20px;
        white-space: initial;
    }
}

@media (min-height: 958px) and (max-height: 1278px) {
    .grid-table table tr td {
        font-size: 13px;
        color: var(--text);
        font-weight: var(--regular);
        padding: 29px 20px;
        white-space: initial;
    }
}

@media (min-height: 1278px) and (max-height: 1917px) {
    .grid-table table tr td {
        font-size: 13px;
        color: var(--text);
        font-weight: var(--regular);
        padding: 56px 20px;
        white-space: initial;
    }
}

@media (min-height: 1917px) {
    .grid-table table tr td {
        font-size: 13px;
        color: var(--text);
        font-weight: var(--regular);
        padding: 70px 20px;
        white-space: initial;
    }
}

.grid-table table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.grid-table table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.grid-table table tr th:first-child {
    border-top-left-radius: 10px;
    background-color: rgb(220, 223, 221);
}

.grid-table table tr th:last-child {
    border-top-right-radius: 10px;
    // padding: 0px 50px;
}

.grid-table table tr {
    background-color: var(--white);
    transition: all 0.4s ease;
}

.grid-table table tr:hover,
.grid-table table tr:hover .action tr {
    background-color: #F6F6F6;
}

.grid-table table tr.thead:hover {
    background-color: var(--white);
}

.action {
    width: 90px !important;
    margin: 0 auto;
}

.action td img {
    width: 16px;
    display: block;
}

.grid-table .pagecount {
    width: auto;
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 18px 0px 5px 0px;
}

.pagecount label {
    margin-right: 15px;
    font-size: var(--font14);

    font-weight: var(--regular);
    font-style: normal;
    color: #3F5010;
}

.grid-table .pagecount label {
    margin-right: 15px;
}

.grid-table .pagecount .bootstrap-select>.dropdown-toggle {
    padding: 7px 10px;
}

// .graphblock img {
//     width: 100%;
// }

/*----------------- custom scrollbar-------------------*/

.custom_scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */

.custom_scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

.custom_scroll::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #73DEEF 0%, #D4F5FB 100%);
    border-radius: 10px;
}

/* Handle on hover */

.custom_scroll::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #73DEEF 0%, #D4F5FB 100%);
}

.axis .domain {
    display: none;
}

.graphblock {
    width: 100%;
    height: 100%;
    text-align: center;
}

.graphblock svg {
    margin: 0 auto;
    cursor: pointer;
}

.dashcol h5 span {
    display: block;
    font-size: 12px;
    text-transform: none;
    font-weight: 400;
}

.graphblock .meantime {
    margin-top: 3em;
}

.meantime .labels2 {
    font-size: 12px;
    font-weight: 300;
}

.meantime .mcircle:nth-child(3) .labels {
    font-size: 16px;
    font-weight: 600;
}

.meantime .mcircle:nth-child(6) .labels {
    font-size: 20px;
    font-weight: 600;
    transform: translateX(-3px);
}

.meantime .mcircle:nth-child(9) .labels {
    font-size: 24px;
    font-weight: 600;
    transform: translateX(-3px);
}

.donut .label {
    font-size: 12px;
    font-weight: 300;
}

.donut .labels {
    font-size: 7px;
    font-weight: 200;
}

.donut .namelabel {
    font-size: 11px;
}

.graphblock h5 {
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: 1px solid #EAEAEA;
    margin: 25px auto;
}

.graphblock h5 span {
    font-size: 16px;
    font-weight: 600;
    display: inline;
}

.donut svg {
    margin-right: 5px;
}

.donut {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin: 0 auto;
}

.graphblock {
    position: relative;
}

.circ {
    box-shadow: 0 0 17px rgb(0 0 0 / 17%);
}

.graph-des-nav ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.graph-des-nav ul li {
    font-size: 12px !important;

    padding-right: 15px;
    margin: 3px 0;
}

.graph-des-nav li span {
    width: 13px;
    height: 13px;
    display: inline-block;
    border-radius: 10px;
    margin-right: 5px;
    vertical-align: middle;
}

.graph-des-nav.floweffnav li {
    display: flex;
    justify-content: center;
    align-items: center;
}

.graph-des-nav.floweffnav li span {
    vertical-align: initial;
}

.flowdisnav.graph-des-nav ul li {
    font-size: 11px;

    padding-right: 12px;
}

.flowdisnav.graph-des-nav li span {
    width: 7px;
    height: 7px;
}

.graph-des-nav li span.dark-blue {
    background-color: var(--blue);
}

.graph-des-nav li span.blue {
    background-color: var(--blue-light);
}

.graph-des-nav li span.predictabiltiy {
    background-color: #00a3ff;
}

.graph-des-nav li span.pink {
    background-color: var(--pink);
}

.graph-des-nav li span.purple {
    background-color: var(--purple);
}

.graph-des-nav li span.dk-orange {
    background-color: #0ebc0e;
}

.graph-des-nav li span.dk-green {
    background-color: #fd7e14;
}

.graph-des-nav li span.yellow {
    background-color: var(--yellow);
}

.graph-des-nav li span.yellow {
    background-color: #ebdc7a;
}

.graph-des-nav li span.orange {
    background-color: var(--orange);
}

.graph-des-nav li span.lgreen {
    background-color: #81A71A;
}

.graph-des-nav li span.lightgreen {
    background-color: #c7de86;
}

.graph-des-nav li span.dyellow {
    background-color: #fec82f;
}

.graph-des-nav li span.lorange {
    background-color: rgb(253, 162, 107);
}

.graph-des-nav li span.lowblue {
    background-color: #98bddf;
}

.graph-des-nav li span.gray {
    background-color: #eaeaea;
}

.graph-des-nav li span.red {
    background-color: #FB543A;
}

.graph-des-nav li span.skblue {
    background-color: #79D2DE;
}

.graph-des-nav li span.ptxt {
    font-weight: 800;
}

.graph-des-nav li span.dashline {
    border: 1px dashed #919191;
}

.graph-des-nav li span.Compliancegreen {
    background-color: #50af50;
}

.graph-des-nav li span.ComplianceRed {
    background-color: #ff6161;
}

.graph-des-nav li span.Pending {
    background-color: #ffda65;
}

.graph-des-nav li span.Deviation {
    background-color: #8faadc;
}

.graph-des-nav li span.Whitelisted {
    background-color: #a9a9a9;
}

.graph-des-nav li span.ComplianceGrey {
    background-color: #a9a9a9;
}

.graph-des-nav li span.VulCritical {
    background-color: #ff6161;
}

.graph-des-nav li span.VulHigh {
    background-color: #ffbe9a;
}

.graph-des-nav li span.VulMedium {
    background-color: #ffd68b;
}

.graph-des-nav li span.VulLow {
    background-color: #79dbe3;
}

.graph-des-nav li span.VulInfo {
    background-color: #deebf7;
}

.cube {
    width: 12px;
    height: 12px;
    display: inline-block;
}

.toggle-control {
    display: block;
    position: relative;
    padding-left: 100px;
    padding-bottom: 10px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.toggle-control input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.toggle-control input:checked~.control {
    background-color: #b1bc93;
}

.toggle-control input:checked~.control:after {
    background-color: #a8cf3e;
}

.toggle-control input:checked~.control:after {
    left: 15px;
}

.toggle-control .control {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 11px;
    width: 30px;
    border-radius: 25px;
    background-color: #939393;
    transition: background-color 0.15s ease-in;
    margin: auto;
}

.toggle-control .control:after {
    content: "";
    position: absolute;
    left: -3px;
    top: -3px;
    width: 16px;
    height: 16px;
    border-radius: 25px;
    background: #fff;
    transition: left 0.15s ease-in;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.46);
}

.toolnav {
    justify-content: flex-end;
}

.btnwrap {
    display: flex;
    margin-left: 20px;
    align-items: center;
    justify-content: flex-end;
}


.btnwrap .solid-btn {
    margin: 0 5px;
}

.btnwrap .solid-btn:last-child {
    margin-right: 0;
}

.wrapper {
    padding: 30px 0 0;
}

.configwrap h4 {
    font-size: var(--font18);
    font-weight: var(--regular);

    color: var(--dark);
    margin-bottom: 6px;
}

.configwrap h6 {
    font-size: var(--font14);
    font-weight: var(--regular);

    color: var(--gray3);
    margin-bottom: 20px;
}

.formwrap {
    background-color: var(--white);
    padding: 60px 80px;
}

.formwrap .default-input label,
.formwrap .radiogroup label {
    font-size: var(--font13);
    color: var(--dark);
}

.formwrap .radiogroup label:first-child {
    margin: 0 20px 8px 0;
    width: 100%;
    display: block;
}

.formwrap .default-input input {
    font-size: var(--font13);
    -webkit-appearance: none;
}

.formwrap .frmgroup.radiogroup {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.formwrap .frmgroup {
    margin-bottom: 22px;
}

.formwrap .form-check-label {
    font-size: var(--font13);
}

.formwrap .row {
    align-items: flex-start;
}

.frmhead {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.error {
    white-space: nowrap;
    color: #d70000;
    margin: -21px;
    border-radius: 4px;
    font-size: 10px;
    margin-left: auto;
    margin-right: 1px;
    margin-bottom: 1px;
}

.errors {
    color: red;
}

.errorinput {
    border: 1px solid #d70000 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.tdico img {
    width: 22px;
}

.toolwrap.custom_scroll {
    min-height: 90vh;
}

.toolwrap h3 span {
    display: block;
    text-transform: none;
    color: #080707;
    font-size: 14px;
    padding-top: 5px;
    font-weight: 400;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgb(63 63 63 / 90%);
    display: none;
    z-index: 9999;
    opacity: 0;
}

.meantimewrap {
    overflow-y: auto;
    height: 99vh;
}

.meanpop-subhead {
    margin-bottom: 40px;
}

.drilldown2 {
    margin-bottom: 0px !important;
}

.meanpop-subhead ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.meanpop-subhead ul li {
    font-size: 16px;
    font-weight: 300;
    // letter-spacing: -0.16px;
    color: var(--content-color);
    //text-transform: capitalize;
    margin-right: 25px;
    margin-bottom: 10px;

}

.meanpop-subhead ul li span {
    margin-right: 5px;
    font-weight: 700 !important;
}

.meanpop-wrap {
    background-color: #F2F4F2;
    border-top: 1px solid #d5d5d5;
    padding: 0px 40px;
    // height: 70vh;
}

.meanpop-wrap p {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.16px;
    color: #4E4E4E;
}

.pagecount {
    width: 14%;
    display: flex;
    justify-content: flex-end;
}

.pagecount .frmgroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.pagecount .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 56px;
}

.pagecount label {
    margin-right: 15px;
    font-size: var(--font14);

    font-weight: var(--regular);
    font-style: normal;
    color: #3F5010;
}

.appname span {
    display: block;
    margin-bottom: 4px;
}

.tdactions {
    display: flex;
    justify-content: flex-end;
}

.actionsBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.actcol {
    padding: 0 15px;
    text-align: center;
}

.actcol img {
    width: 20px;
    height: 20px;
}

.actcol.disabled img {
    filter: grayscale(1);
    opacity: 0.5;
}

.actcol a span {
    display: block;
    font-size: 11px;

    color: var(--primary);
    text-align: center;
    margin-bottom: 10px;
}

.config-wrap {
    padding: 22px 30px 30px;
    border-radius: 6px;
}

.form-check-input:checked[type="checkbox"] {
    background-image: url(../images/checked.svg);
    background-color: #fff;
    background-size: 10px 8px;
    border-color: var(--primary);
}

.form-check-input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
    -webkit-border-radius: 3px !important;
    border-radius: 3px !important;
    border: 1px solid var(--black) !important;
}

.btnwrap {
    display: flex;
    justify-content: flex-end;

}

.btnwrap button {
    margin: 5px 8px;
}

.btnwrap button:last-child {
    margin-right: 0;
}

.modal .bootstrap-select>.dropdown-toggle {
    font-style: normal;
}

.usertags a img {
    width: 12px;
    width: 12px;
}

.main-content-wrap.appwrap {
    padding: 17px 0 30px;
}

.bootstrap-select .dropdown-menu li a.dropdown-item {
    padding: 12px 10px;
}

.bootstrap-select .dropdown-menu {
    min-width: 100%;
}

.form-check-label.checked {
    border: 1px solid var(--primary);
    background-color: #fff;
    color: var(--text);
}

.toolboard-row {
    background-color: var(--white);
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 15px 20px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toolboard-row h4 {
    font-size: 16px;

    font-weight: var(--bold);
    color: var(--text);
    text-transform: capitalize;
}

.outline-button {
    border: 1px dashed #B4B4B4;
    font-size: 12px;
    font-weight: var(--regular);
    color: #B4B4B4;
    padding: 8px 15px;
    display: inline-block;
}

.outline-button {
    border: 1px dashed var(--primary);
    color: var(--primary);
    border-radius: 6px;
}

.outline-button:hover {
    border: 1px dashed var(--primary);
    background-color: var(--primary);
    color: var(--white);
}

.outline-button span {
    margin-right: 5px;
}

.toolright .groupinput {
    display: flex;
    margin: 0;
}

.toolright .groupinput input {
    border: 1px solid #D5D5D5;
    font-size: 12px;
    color: var(--text);
    padding: 8px 12px;
    margin-right: 10px;
    border-radius: 5px;
}

.toolright .solid-btn {
    font-size: 12px;

    font-weight: var(--regular);
    border-radius: 6px;
    padding: 8px 12px;
    text-transform: none;
}

.usertags {
    background-color: #EAEAEA;
    border-radius: 20px;
    font-size: 12px;

    color: var(--text);
    padding: 8px 12px;
    display: inline-block;
    margin: 5px;
    border: 2px solid var(--primary);
}

.usertags a {
    margin-left: 5px;
}

.usertags a img {
    width: 12px;
    width: 12px;
}

.toolright .outline-button {
    width: 100px;
}

@keyframes growProgressBar {

    0%,
    33% {
        --pgPercentage: 0;
    }

    100% {
        --pgPercentage: var(--value);
    }
}

@property --pgPercentage {
    syntax: "<number>";
    inherits: false;
    initial-value: 0;
}

.process-circle[role=progressbar] {
    --size: 6rem;
    --fg: #147ad6;
    --bg: #eaeaea;
    --pgPercentage: var(--value);
}

.process-circle.prcircle2[role=progressbar] {
    --size: 6rem;
    --fg: var(--primary);
    --bg: #eaeaea;
    --pgPercentage: var(--value);
}

.process-circle p {
    margin-top: -4px;
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 600;
}

.process-circle p span {
    color: #c7c4c4;
}

.scrollable {
    overflow-y: auto;
}

.error-message {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    padding: 0 5px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 20px;
}

@media (max-width: 380px) {
    .dashactnav.actnav .frmgroup {
        width: 100%;
    }
}

@media (max-width: 420px) {
    .btnwrap button {
        width: 100%;
        margin: 8px 0;
    }
}

@media (max-width: 480px) {

    .meantable tr th,
    .meantable tr td {
        font-size: 14px;
    }

    .dashactnav .actright {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .dashactnav.actnav .frmgroup {
        width: 49%;
        margin-bottom: 7px;
        margin-right: 0;
    }

    .meanpop-subhead ul li span {
        display: block;
        margin-bottom: 5px;
    }

    .meanpop-subhead ul li {
        margin-bottom: 18px;
    }

    .meantable tr th {
        width: 140px;
    }

    .graph-des-nav ul {
        flex-wrap: wrap;
    }

    .graph-des-nav ul li {
        width: 50%;
        display: block;
        text-align: left;
        margin-bottom: 5px;
    }

    .graph-des-nav.velonav ul li {
        width: 100%;
    }

    .filterwrap .frmgroup {
        width: 100%;
        margin-right: 0;
    }

    .filterwrap .filtrbtn {
        margin-left: 0px;
    }

    .managecol h4 .managecol {
        width: 48%;
    }

    .mtr {
        width: 64%;
    }

    .meantime-head {
        font-size: 12px;
        margin: 0 15px;
    }

    .custom_scroll {
        padding-right: 8px;
    }

    .cube {
        width: 10px;
        height: 10px;
    }

    .configwrap .btnwrap .solid-btn {
        margin: 5px auto;
        width: 100%;
    }

    .configwrap .btnwrap {
        flex-wrap: wrap;
    }

    .grid-table.toolstable tr td:nth-child(3) {
        width: 100%;
        display: block;
        padding: 3px 10px 12px;
    }

    .toolstable.grid-table tr td:nth-child(3):before {
        margin-left: -10px;
        margin-bottom: 12px;
    }

    .toolright .groupinput {
        display: flex;
        margin: 0;
        flex-wrap: wrap;
    }

    .toolright .groupinput input {
        margin-top: 20px;
        margin-right: 0;
        border-radius: 5px;
        width: 100%;
    }

    .config-wrap {
        padding: 22px 15px 30px;
    }
}

@media (max-width: 680px) {

    .managecol {
        width: 36%;
    }

    .offcanvas {
        padding: 60px 15px 30px 15px;
    }

    .accordion-button::after {
        width: 20px;
        height: 20px;
        background-size: 58%;
    }

    .stories-list .bullet-list ol {
        margin-left: 13px;
    }

    .toolstable.grid-table tr td:before {
        width: 50%;

        font-weight: var(--bold);
    }

    .intype .managecol {
        width: 55%;
    }

    .btnwrap button:last-child {
        margin-right: 0;
        width: 100%;
    }

    .btnwrap {
        flex-wrap: wrap;
    }
}

@media (max-width: 767px) {

    .dashactnav .actright,
    .dashactnav.actnav .frmgroup {
        width: 100%;
    }

    .offcanvas {
        padding: 60px 25px 30px 25px;
    }

    .stories-list ol li {
        font-size: var(--font14);
    }

    .offcanvas .btn-close {
        color: var(--white) !important;
        left: auto;
        right: 0;
        background-color: var(--primary);
        top: 0;
        border-radius: 0;
    }

    .offcanvas-end {
        width: 100%;
    }

    .filterwrap .frmgroup {
        width: 46%;
    }

    .btnwrap {
        margin-left: 0;
    }

    .solid-btn {
        padding: 10px 12px;
    }

    .stories-list ol li {
        padding: 15px 0px 15px 20px;
    }

    .incidnt-type-wrap {
        flex-wrap: wrap;
    }

    .managerow {
        flex-wrap: wrap;
    }

    .custom_scroll::-webkit-scrollbar {
        width: 6px;
    }

    .flowhead {
        font-size: 18px;
    }

    .meantime-head {
        margin: 0 15px;
    }

    .flow-descriptions-block .accordion-button span {
        margin-right: 10px;
    }

    .formwrap {
        padding: 40px 30px;
    }

    .meanpop-subhead {
        padding: 0 20px 15px 20px;
        bottom: 0px !important;
    }

    .meanpop-wrap {
        padding: 20px 20px;
    }

    .col-lg-4.col-md-6.sratecol {
        flex: 0 0 auto;
        width: 100%;
    }

    .fltrhead .graph-des-nav {
        margin-left: 0;
        width: 85%;
    }

    a.viewlink {
        width: 15%;
    }

    .fltrhead {
        flex-wrap: wrap;
    }

    .toolboard-row {
        flex-wrap: wrap;
    }

    .toolright {
        width: 100%;
        margin-top: 20px;
    }
}

@media (max-width: 860px) {

    .grid-table td {
        width: 100%;
    }

    .grid-table table {
        width: 100%;
    }

    .grid-table thead {
        display: none;
    }

    .grid-table table tr td {
        display: flex;
        text-align: left;
        border: 0;
        padding: 10px 0px 10px 0;
        border-bottom: 1px solid #d5d5d5;
        justify-content: flex-start;
        align-items: center;
        font-size: var(--font13);
    }

    .grid-table tbody td:before {
        content: attr(data-th);
        display: block;
        font-size: var(--font14);

        color: #4e4e4e;
        font-weight: var(--bold);
        padding: 12px 10px;
        width: 40%;
        text-align: left;
        margin-right: 20px;
        background-color: #fff;
    }

    .action td:before {
        display: none !important;
    }

    .tdactions {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        margin: 0 auto;
    }

    .grid-table .action tr {
        display: inline-flex;
    }

    .action {
        margin: 0;
    }

    .grid-table table tr {
        border-bottom: 8px solid #f2f4f2;
    }

    .alert {
        width: 75%;
    }

    .alert strong {
        margin-right: 0;
        display: block;
        margin-bottom: 10px;
    }

    .meanpop-wrap {
        height: auto;
    }

    .filtercol.col-lg-4 {
        flex: 0 0 auto;
        width: 100%;
    }

    .btnwrap button {
        margin: 5px 8px;
    }
}

@media (max-width: 991px) {

    .actright {
        width: 100%;
        justify-content: flex-end;
    }

    .filterwrap .frmgroup {
        width: 31%;
        margin-bottom: 10px;
    }

    .filterwrap .filtrbtn {
        margin-top: 0;
    }

    .filterwrap {
        justify-content: space-between;
    }

    .numdes {
        font-size: 25px;
    }

    .flownum {
        font-size: 30px;
    }

    .numdes {
        font-size: 20px;
    }

    .incidnt-numbox {
        flex-wrap: wrap;
    }

    .managecol h4 {
        font-size: 12px;
    }

    .managecol {
        width: 24%;
    }

    .peplemid {
        width: 100%;
        margin-bottom: 10px;
    }

    .configwrap .formwrap .frmgroup {
        margin: 15px 0;
    }

    .grid-table table tr td {
        font-size: 13px;
        padding: 10px 20px;
    }

    .grid-table .toggle-control {
        padding-left: 0;
    }
}

@media (max-width: 1200px) {
    .meanpop-subhead ul {
        display: block;
    }

    .meanpop-subhead ul li {
        margin: 10px 0;
    }
}

@media (max-width: 1260px) {
    .actnav {
        grid-template-columns: auto;
    }

    .dashactnav .actleft,
    .dashactnav .actright {
        margin-bottom: 15px;
    }

    .dashfltr {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .dashfltr .appbtn {
        margin-left: 17px;
    }

    .flowbox-row {
        display: flex;
        flex-wrap: wrap;
    }

    .flowbox h2 {
        font-size: 35px;
    }

    .flowbox h4 {
        font-size: 14px;
    }

    .filternav,
    .filterwrap {
        flex-wrap: wrap;
    }

    .filterwrap {
        width: 100%;
        order: 2;
    }

    .filternav {
        justify-content: end;
    }

    .filterwrap .frmgroup {
        width: 17%;
    }

    .pagecount {
        width: 100%;
    }

    .managecol {
        width: 18%;
    }

    .managecol h4 {
        font-size: 14px;
    }

    .meanpop-subhead ul {
        justify-content: flex-start;
    }

    .col-lg-4.col-md-6.sratecol {
        flex: 0 0 auto;
        width: 50%;
    }

    .filtercol.col-lg-4 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-lg-4.filtercol.appstscol {
        flex: 0 0 auto;
        width: 100%;
    }

    .filtercol.col-lg-8 {
        flex: 0 0 auto;
        width: 100%;
    }

    .order-lg-4 {
        order: 5 !important;
    }

    .order-lg-5 {
        order: 4 !important;
    }

    .changereqperrisk {
        height: 240px;
        margin-top: 0;
    }
}

@media (max-width: 1350px) {

    .default-input label,
    .radiogroup label,
    .pagecount label {
        font-size: var(--font13);
    }
}

@media (max-width: 1366px) {

    .actnav {
        grid-template-columns: 36% 53% 11%;
    }

    .graph-des-nav ul li {
        font-size: 11px;
    }
}