@import url("../../../assets/css/root.scss");

.detailsFilterStyle {
    min-width: 90px;
    font-size: var(--button-size);
    background-color: var(--button-bg-color) !important;
    color: var(--button-text-color);
     margin-right: 5px;
    height: 38px;
    float: right;
    border: 1px solid var(--button-bg-color);
    &:hover {
      color: var(--button-bg-color);
      background-color: var(--button-text-color) !important;
    }
  }

.sort-icon {
    /* Reduce the fill color */
    fill: #888;
    /* Adjust the opacity */
    opacity: 0.6;
}

.sorted-icon {
    /* Reduce the fill color */
    fill: #3f91d8;
}

#popupdata {
    width: 100%;
    border-collapse: collapse;
    border-color: inherit;
}

#popupdata td,
#popupdata th {
    text-align: center;
    border: 1px solid #ddd;
    padding: 8px;
}

.modalprojectemty {
    background: white !important;
    color: var(--primary) !important;
    text-align: left !important;
    position: relative !important;
    text-align: center !important;
    padding: 12px !important;
    height: 50px !important;
    top: 0px !important;
    bottom: 10px !important;
    font-size: 13px !important;
    font-weight: bold !important;
    border: 2px solid #F2F4F2 !important;
}

.th-serverTable-border {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 0px 1px rgb(229, 229, 229) !important;
}

.serverTable thead {
    box-shadow: 0px 0px 0px 2px rgb(229, 229, 229) !important;
}

.serverTable thead tr {
    box-shadow: 0px 0px 0px 1px rgb(229, 229, 229) !important;
}

.tableHeaderTooltip {
    opacity: 1;
}
.rowSpace{
    padding-block: 3px !important;
    line-height: 1 !important;
}
.defaultfilterStyle {
    font-size: 11px !important;
    height: 19px;
    min-width: 90px;
    font-size: var(--button-size);
    color: var(--primary-light) !important;
    background-color: var(--primary) !important;
    margin-right: -90px;
    float: right;
    margin-bottom: 20px;
    border: 1px solid var(--button-bg-color);

    &:hover {
        color: var(--button-bg-color) !important;
        background-color: var(--dark-theme-selected-opt) !important;
    }
}

.defaultfilterStyle.btn {
    padding: 0px !important;
}

.clearfilterStyle {
    margin-top: 22px;
    font-size: 11px !important;
    min-width: 90px;
    font-size: var(--button-size);
    color: var(--button-text-color) !important;
    background-color: var(--pink) !important;
    margin-right: 5px;
    height: 19px;
    float: right;
    border: 1px solid var(--button-bg-color);

    &:hover {
        color: var(--button-bg-color) !important;
        background-color: var(--button-text-color) !important;
    }
}

.clearfilterStyle.btn {
    padding: 0px !important;
}

.clearfilterStyle.btn:disabled {
    background-color: var(--pink) !important;
    color: var(--dark-theme-selected-opt) !important;
    border: 1px solid var(--dark-theme-selected-opt);
}

.highlightRow {
    background-color: #ececec !important;
    font-weight: 600 !important;
}

.homePageBulletsisk {
    list-style-type: disc !important;
}

.table_bar li.nav-item {
    margin-right: 10px;
}

.table_bar li.nav-item:last-child {
    margin-right: 0;
}

.Table-Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stickyColumnHeader {
    box-shadow: inset -1px 0px rgba(0, 0, 0, 0.1) !important;
    border-right: 0px !important;
}

.nonStickyColumnHeader {
    box-shadow: none !important;
    border-right: 1px solid #c0c0c0 !important;
}

.sticky-left-border {
    border-left: 0px !important;
}

.non-sticky-left-border {
    border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.stickyColumnCell {
    box-shadow: inset -1px 0px rgba(0, 0, 0, 0.1) !important;
    border-right: 0px !important;
}

.nonStickyColumnCell {
    box-shadow: none !important;
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.sticky-left-border-cell {
    border-left: 0px !important;
    box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.1),
        inset -1px 0 0 rgba(0, 0, 0, 0.1) !important;
}

.non-sticky-left-border-cell {
    border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.far-back-icon {
    background: none;
    border: none;
}

.far-back-icon:hover svg path {
    fill: #102250;
}

.filter_to_left {
    position: absolute;
    top: 102px;
}

.cisoSearchStyle {
    margin-right: 5px !important;
}

@media screen and (max-width: 1087px) {
    .searchmargin {
        // margin-top: 3px;
        margin-bottom: 3px;
    }
}

.padForFilter {
    padding: 0px;
}

.marForFilter {
    margin: 0px;
}

.search-input, .input-group.serverInput.searchStyle.manageVulStyle.searchmargin .search-input {
    color: #595959 !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    border: none !important;
    height: 38px !important;
    background-color: #ECECEC !important;
    border-start-start-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    line-height: 18px !important;
}

.search-btn {
    background-color: #ECECEC !important;
    border: none !important;
    border-radius: 12px;
    font-size: 17px !important;
    color: #595959 !important;
}

.rectangle {
    width: 27px;
    height: 18px;
    background: #ECECEC;
    border-radius: 3px;
    text-align: center;
    line-height: 18px;
    margin-top: 1px;
    border: none;
}

.rectangle-Rows {
    width: 47px !important;
    height: 20px !important;
    background: #ECECEC;
    border-radius: 3px;
    text-align: center;
    margin-top: 11px !important;
    border: none;
}

.of-1 {
    height: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 11.1727px;
    text-align: right;
    color: #595959;
    padding-left: 8px;
}

.custom_table_vul {
    font-size: 13px;
    width: -webkit-fill-available;
    white-space: nowrap;
}

.custom_table_vul thead {
    position: sticky;
    top: 0;
    background: white;
}

/* Add this CSS to your stylesheet */
.custom_table_vul thead {
    position: inherit;
    top: 0;
    background-color: #fff;
    z-index: 1;
    box-shadow: 0px -1px 2px 0px rgb(229 229 229);
}

.statusFilter .custom-select.css-b62m3t-container {
    width: 208px;
}

.checkStyle {
    height: 43px;
}
.tableBtn{
    li:first-child{
        margin-right: 0px;
    }
}
.tableStyle {
    .custom-select {
        margin-right: 5px;
        // margin-bottom: 15px;
    }
}

.disabled-icon:hover svg path {
    fill: #939393;
    cursor: default;
}

/* Media query for screens up to 1161px */
@media (max-width: 1161px) {
    .marForFilter {
        margin-top: 4px;
    }
}

/* Media query for screens up to 1161px */
@media (max-width: 1161px) {
    .marForFilter {
        margin-top: 4px;
    }
}

@media (max-width: 1200px) and (min-width: 0px) {
    .filter_to_left {
        position: static;
        top: auto;
    }
}